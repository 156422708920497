import React, { Component, useState } from "react";
import { Grid, Typography, Paper, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import logo from "../../assets/img/logo-oh-small.png";
import SearchField from "./SearchField";
import Menu from '@material-ui/core/Menu';
const styles = theme => ({
  image: {
    width: 80,
    height: "auto"
  },
  imageForSmallScreen:{
    width: 65,
    height: "auto"
  },
  container: {
    flexGrow: 1,
    padding: 16
  }
});

  function Logo(props) {
    const [anchorQuickSearch, setAnchorQuickSearch] = useState(null);
    const quickResultsOpen = Boolean(anchorQuickSearch)
    const { classes } = props;
    const handleSearchResultsClose = (event) => {
      setAnchorQuickSearch(null);
    }
    const renderQuickSearchResults = (
      <Menu
        anchorEl={anchorQuickSearch}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={quickResultsOpen}
        onClose={handleSearchResultsClose}
      ></Menu>
    );
    return (
      <Paper className={classes.container}>
        <Grid container spacing={1}>
          <Grid container alignItems="center" item xs={12} sm={9} spacing={1}>
          <Hidden smUp>
            <Grid item>
            <a href="https://optionenhandeln.ch/" target="_blank" rel="noopener" >
              <img src={logo} alt="logo" className={classes.imageForSmallScreen} />
            </a>
            </Grid>
            </Hidden>
            <Hidden xsDown>
            <Grid item>
            <a href="https://optionenhandeln.ch/" target="_blank" rel="noopener" >
              <img src={logo} alt="logo" className={classes.image} />
            </a>
            </Grid>
            </Hidden>
            <Grid item>
              <Typography
                variant="h5"
                color="primary"
              >
                StrategySelector
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Logo)));
