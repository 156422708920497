import React, { Component } from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { strategySelectionMethod } from "./StrategySelection";
import {updateData,updateList} from "../../store/actions/dataActions"
import Strategies from "./Strategies";
import { LONG_CALL } from "./ProfitAndLossDiagram";
const styles = theme => ({
  paperConatiner: {
    padding: 16
  },
  specialDropDown: {
    width: '100%'
  }
 
});
const marketOutlook = [
  {
    value: "All",
    label: "All"
  },
  {
    value: "Bull",
    label: "Bull"
  },
  {
    value: "Bear",
    label: "Bear"
  },
  {
    value: "Neutral",
    label: "Neutral"
  }
];

const volatilityView = [
  {
    value: "All",
    label: "All"
  },
  {
    value: "Increase",
    label: "Increase"
  },
  {
    value: "Neutral",
    label: "Neutral"
  },
  {
    value: "Decrease",
    label: "Decrease"
  }
];

const timeErosion = [
  {
    value: "All",
    label: "All"
  },
  {
    value: "Helps",
    label: "Helps"
  },
  {
    value: "Neutral",
    label: "Neutral"
  },
  {
    value: "Hurts",
    label: "Hurts"
  }
];

const dividends = [
  {
    value: "All",
    label: "All"
  },
  {
    value: "Increase",
    label: "Increase"
  },
  {
    value: "Neutral",
    label: "Neutral"
  },
  {
    value: "Decrease",
    label: "Decrease"
  }
];

const interest = [
  {
    value: "All",
    label: "All"
  },
  {
    value: "Increase",
    label: "Increase"
  },
  {
    value: "Neutral",
    label: "Neutral"
  },
  {
    value: "Decrease",
    label: "Decrease"
  }
];

const profit = [
  {
    value: "All",
    label: "All"
  },
  {
    value: "Limited",
    label: "Limited"
  },
  {
    value: "Unlimited",
    label: "Unlimited"
  }
];

const loss = [
  {
    value: "All",
    label: "All"
  },
  {
    value: "Limited",
    label: "Limited"
  },
  {
    value: "Unlimited",
    label: "Unlimited"
  },
  {
    value: "Substantial",
    label: "Substantial"
  }
];

const optionStrategiesList = [
  LONG_CALL,
  "Short Call",
  "Long Put",
  "Short Put",
  "Covered Call",
  "Covered Put",
  "Protective Call",
  "Protective Put",
  "Bull Call Spread",
  "Bear Put Spread",
  "Long Butterfly",
  "Short Butterfly",
  "Long Condor",
  "Short Condor",
  "Long Straddle",
  "Short Straddle",
  "Long Strangle",
  "Short Strangle",
  "Ratio Call Spread",
  "Ratio Put Spread",
  "Collar",
  "Reversal",
  "Conversion",
  "Long Calendar Spread",
  "Short Calendar Spread"
];

class Settings extends Component {
  handleChange = name => event => {
    this.props.updateData(name,event.target.value)
    var result = strategySelectionMethod(this.props, name, event);
    this.props.updateList(result);
  };
  handleChangeText = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  render() {
    const { classes ,MarketOutlook,VolatilityView,TimeErosion,Dividends,Interest,Profit,Loss,OptionStrategiesList} = this.props;
    return (
        <Paper className={classes.paperConatiner}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} container spacing={1} alignContent='flex-start' >
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color='primary'
                    gutterBottom
                  >
                    Settings
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper style={{ padding: 16}}>
                    <Grid item container spacing={1}>
                    <Grid item xs={12} >
                  <TextField
                    id="marketOutlook"
                    label="Market Outlook"
                    select
                    value={MarketOutlook}
                    className={classes.specialDropDown}
                    onChange={this.handleChange("marketOutlook")}
                    margin="normal"
                    variant="outlined"
                  >
                    {marketOutlook.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    id="volatilityView"
                    label="Volatility View"
                    select
                    value={VolatilityView}
                    className={classes.specialDropDown}
                    onChange={this.handleChange("volatilityView")}
                    margin="normal"
                    variant="outlined"
                  >
                    {volatilityView.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    id="timeErosion"
                    label="Time Erosion"
                    select
                    value={TimeErosion}
                    className={classes.specialDropDown}
                    onChange={this.handleChange("timeErosion")}
                    margin="normal"
                    variant="outlined"
                  >
                    {timeErosion.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    id="dividends"
                    label="Dividends"
                    select
                    value={Dividends}
                    className={classes.specialDropDown}
                    onChange={this.handleChange("dividends")}
                    margin="normal"
                    variant="outlined"
                  >
                    {dividends.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    id="interest"
                    label="Interest"
                    select
                    value={Interest}
                    className={classes.specialDropDown}
                    onChange={this.handleChange("interest")}
                    margin="normal"
                    variant="outlined"
                  >
                    {interest.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    id="profit"
                    label="Profit"
                    select
                    value={Profit}
                    className={classes.specialDropDown}
                    onChange={this.handleChange("profit")}
                    margin="normal"
                    variant="outlined"
                  >
                    {profit.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    id="loss"
                    label="Loss"
                    select
                    value={Loss}
                    className={classes.specialDropDown}
                    onChange={this.handleChange("loss")}
                    margin="normal"
                    variant="outlined"
                  >
                    {loss.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                    </Grid>
                  </Paper>
                </Grid>
               
            </Grid>
            <Strategies optionStrategies={OptionStrategiesList} parameters={this.props}/>
          </Grid>
        </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    MarketOutlook:state.data.marketOutlook,
    VolatilityView:state.data.volatilityView,
    TimeErosion:state.data.timeErosion,
    Dividends:state.data.dividends,
    Interest:state.data.interest,
    Profit:state.data.profit,
    Loss:state.data.loss,
    OptionStrategiesList:state.data.optionStrategiesList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateData: (name,valueOfAttribute) => dispatch(updateData(name,valueOfAttribute)),
    updateList: (data) => dispatch(updateList(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Settings)));
