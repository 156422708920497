import React, { Component } from 'react'
import { Typography, withStyles, ButtonBase, Grid } from '@material-ui/core';
import { green, grey, orange } from '@material-ui/core/colors';


const styles = theme => ({
  paper: {
    width: '100%',
    height: 200,
    position: 'relative',
    borderRadius: 5,
    '&:hover': { //'&:hover, &$focusVisible'
      zIndex: 1,
      transform: 'scale(1.02)',
      '& $imageBackdrop': {
        opacity: 0.4,
      },
      // '& $imageMarked': {
      //   opacity: 0,
      // },
      // '& $imageTitle': {
      //     border: '4px solid currentColor',
      // },
    },
    transition: theme.transitions.create('scale'),
  },
  imageSrc: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    backgroundPosition: "center center",
    opacity: 0.3,
    borderRadius: 'inherit',

  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.15,
    transition: theme.transitions.create('opacity'),
    borderRadius: 'inherit',
  },
  container: {
    position: 'aboslute',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  textContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingTop: 16,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5
  },
  iconContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 5
  },
  addContainer: {
    position: 'absolute',
    top:16,
    right: 16,
    width: 16,
    height: 16,
    zIndex: 5
  },
  freeIcon: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: green[500],
    backgroundColor: green[500],
    borderRadius: '5px',
    width: 50,
    float: 'right',
    zIndex: 5,
  },
  comingSoonIcon: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: orange[500],
    backgroundColor: orange[500],
    borderRadius: '5px',
    width: 100,
    float: 'right',
    zIndex: 5,
    marginRight: 8
  },
  greenText: {
    color: '#ffffff',
    zIndex: 5
  },
  descriptionText: {
    height: 70,
    zIndex: 5,
    float: 'left'
  },
})

class AppItem extends Component {


  state = {
    mouseOver: false
  }

  openApp = () => {
    if (this.props.app.released) {
      console.log(this.props.app)
      window.open(this.props.app.link)
    }
  }

  render() {

    const { name, description, image, logo, released, cost, mainCategory } = this.props.app
    const { classes, onClick } = this.props

    const backgroundImage = image && image.length > 0 ? image : ''
    return (
      <div>
        <ButtonBase className={classes.paper} onClick={onClick}>
          <span className={classes.imageSrc} style={{ backgroundImage: `url(${backgroundImage})`, }} />
          <span className={classes.imageBackdrop} />
          <div className={classes.container}>
            <div className={classes.textContainer}>
              <Grid container spacing={1}>
                <Grid container item spacing={1} xs={12}>
                  <Grid item>
                    <div style={{ width: 40, height: 40, borderRadius: 8, background: '#ffffff' }}>
                      <img src={logo} alt="" style={{ width: 30, height: 'auto', position:'relative', top: '50%', transform: 'translateY(-50%)'}} />
                    </div>
                  </Grid>
                  <Grid item>
                    <Typography
                      align='left'
                      variant='h5'
                      color='primary'
                      style={{ marginTop: -5}}
                    >
                      {name}
                    </Typography>
                    <Typography
                      align='left'
                      variant='caption'
                      style={{ marginTop:-2, color: grey[600], float: 'left'}}
                    >
                      {mainCategory}
                    </Typography>
                  </Grid>
                </Grid>
                
                <Grid item>
                  <Typography align='left' variant='caption' className={classes.descriptionText} >{description}</Typography> 
                </Grid>
              </Grid>


              {/* <div className={classes.addContainer} >
                <AddIcon style={{ width: 16, height: 16, color: green[500]}} />
              </div> */}
              <div className={classes.iconContainer}>
                { cost === 'free' ?
                  <div className={classes.freeIcon}>
                    <Typography variant='caption' className={classes.greenText}>FREE</Typography>
                  </div>
                  : 
                  <div className={classes.freeIcon}>
                    <Typography variant='caption' className={classes.greenText}>PAID</Typography>
                  </div>
                }
                { !released ?
                  <div className={classes.comingSoonIcon}>
                    <Typography variant='caption' className={classes.greenText}>COMING SOON</Typography>
                  </div> : null
                }
              </div>

            </div>


          </div>


        </ButtonBase>

      </div>
    )
  }
}

export default withStyles(styles)(AppItem)