import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Logo from "../components/optionStrategySelector/Logo";
import Stock from "../components/optionStrategySelector/Stock";
import Settings from "../components/optionStrategySelector/Settings";
import { PRIMARY, OH_LOGO } from "../config/config";

const styles = theme => ({
  mainContainer: {
    padding: 16,
    backgroundColor: PRIMARY,
    flexGrow: 1
  }
});

class OptionStrategySelector extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainContainer}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Logo />
          </Grid>
          
          <Grid item xs={12}>
            <Settings />
          </Grid>

          <Grid item xs={12}>
                        <div style={{ margin: '16px 16px 16px 16px'}}>
                            <Grid >
                               <Grid item style={{ display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant='caption' style={{ color: '#ffffff', position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)', transform: 'translatey(-50%)' }}>Powered by</Typography>
                                </Grid> 
                                <Grid item style={{ display: 'flex', justifyContent: 'center'}}>
                                <a href="https://optionenhandeln.ch/" target="_blank" rel="noopener" >
                                    <img src={OH_LOGO} alt='https://optionenhandeln.ch/' style={{ height: 'auto', width: '100px' }} />
                                </a>
                                </Grid>
                                
                            </Grid>
                    </div>
           </Grid>

        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(OptionStrategySelector)));
